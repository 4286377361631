import React, { useState } from "react";
import tw from "twin.macro";
import axios from "axios";
import Swal from "sweetalert2";
import { SectionHeading } from "components/misc/Headings.js";
import {
  Container as ContainerBase,
  ContentWithPaddingXl,
} from "components/misc/Layouts";
import { ReactComponent as PhoneNewsletterIconBase } from "../../images/phone-call-svgrepo-com.svg";
import { PrimaryButton } from "components/misc/Buttons.js";

import { generateHashInfo } from "../../helpers/secretKeyFunctions";

const Container = tw(
  ContainerBase
)`my-8 lg:my-10 bg-primary-900 text-gray-100 -mx-8 px-8`;
const Row = tw.div`flex items-center justify-center flex-col lg:flex-row px-8`;
const TextColumn = tw.div`flex items-center flex-col sm:flex-row`;
const FormColumn = tw.div`mt-12 lg:mt-0 lg:ml-16 w-full sm:w-auto`;

const EmailNewsletterIcon = tw(
  PhoneNewsletterIconBase
)`w-16 h-16 text-blue-300 mr-6`;
const HeadingInfoContainer = tw.div`sm:ml-6 mt-6 sm:mt-0`;
const Heading = tw(SectionHeading)`text-gray-100 sm:text-left leading-none`;
const Description = tw.p`text-gray-500 font-medium max-w-sm mt-6 sm:mt-6 text-center sm:text-left`;

const Form = tw.form`max-w-sm mx-auto text-center`;
const Input = tw.input`w-full block sm:inline-block px-6 py-4 rounded bg-secondary-600 tracking-wider font-bold border border-secondary-600 focus:border-secondary-300 focus:outline-none sm:rounded-r-none hover:bg-secondary-500 transition duration-300 text-gray-200 my-2`;
const Button = tw(
  PrimaryButton
)`w-full sm:w-auto mt-6 mt-1 sm:rounded-l-none py-4 bg-blue-500 text-gray-100 hocus:bg-blue-700 hocus:text-gray-300 border border-blue-500 hocus:border-green-700`;

const Select = tw.select`block py-2 px-0 mt-1 w-full text-lg text-gray-900 bg-transparent border-0 border-b-2 border-gray-200 appearance-none dark:text-gray-400 dark:border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200`;
const SelectOption = tw.option`text-primary-900`;
const ContainerLoading = tw.div`mt-2`

export default ({}) => {
  const [loading, setLoading] = useState(false);

  const sendMail = async (jsonData) => {
    const apiUrl = `${process.env.REACT_APP_API_MAIL_URL}/api/mail`;
    try {
      const { code, hash, time } = generateHashInfo(
        process.env.REACT_APP_NOTIFICATION_KEY,
        "/api/mail"
      );
      await axios.post(apiUrl, jsonData, {
        headers: {
          "x-auth-motocity": hash,
          time,
          code,
        },
      });
      Swal.fire({
        title: "Solicitud enviada con éxito",
        icon: "success",
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "No se pudo enviar la solicitud, inténtalo de nuevo más tarde",
      });
    }
  };

  const anyEmptyField = (phone, name, service) => {
    if (phone == "" || name == "" || service == "" || service == "none") {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Algunos campos están vacíos",
      });
      return true;
    }
    return false;
  };

  const onSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const phone = document.getElementById("number").value;
    const name = document.getElementById("name").value;
    const service = document.getElementById("serviceSelect").value;
    let emptyField = anyEmptyField(phone, name, service);
    if (!emptyField) {
      const params = {
        name,
        phone,
        service,
      };
      const templade_id = 1;
      const jsonData = {
        params,
        template_id: templade_id,
      };
      await sendMail(jsonData);
    }
    setLoading(false);
  };

  return (
    <Container>
      <ContentWithPaddingXl>
        <Row>
          <TextColumn>
            <EmailNewsletterIcon />
            <HeadingInfoContainer>
              <Heading>Servicio personalizado</Heading>
              <Description>
                Para brindarte un servicio personalizado puedes escribir un
                número en el que te podamos contactar. ¡Estamos listos para
                atender tus solicitudes!
              </Description>
            </HeadingInfoContainer>
          </TextColumn>
          <FormColumn>
            <Form onSubmit={onSubmit}>
              <Input
                id="name"
                name="name"
                type="text"
                placeholder="Ingresa tu nombre"
              />
              <Input
                id="number"
                name="number"
                type="text"
                placeholder="Ingresa tu número"
              />
              <Description>Selecciona el servicio que necesitas</Description>
              <label for="underline_select" class="sr-only">
                Underline select
              </label>
              <Select id="serviceSelect">
                <SelectOption selected disabled value="none">
                  Elige una opción
                </SelectOption>
                <SelectOption value="Información General">
                  Información General
                </SelectOption>
                <SelectOption value="Domicilios">Domicilios</SelectOption>
                <SelectOption value="Productos para motos">
                  Productos para motos
                </SelectOption>
                <SelectOption value="Pagos y recaudos">
                  Pagos y recaudos
                </SelectOption>
                <SelectOption value="Trámites y diligencias">
                  Trámites y diligencias
                </SelectOption>
                <SelectOption value="Transporte de mercancía">
                  Transporte de mercancía
                </SelectOption>
              </Select>
              <Button type="submit" disabled={loading}>
                Enviar
              </Button>
              {loading ? (
                <ContainerLoading>
                  <svg
                    aria-hidden="true"
                    class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                </ContainerLoading>
              ) : null}
            </Form>
          </FormColumn>
        </Row>
      </ContentWithPaddingXl>
    </Container>
  );
};
