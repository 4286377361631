import moment from "moment-timezone"
import crypto from "crypto-js"

const generateCurrentDate = () => {
    return moment().unix();
};

const generateCode = (n) => {
    let code = '';
    for (let i = 0; i < n; i++) {
      code += `${Math.floor(Math.random() * 10)}`;
    }
    return code;
};
  
export const generateHashInfo = (token, path) => {
    const time = generateCurrentDate().toString();
    const code = generateCode(10);
    const payload = JSON.stringify({ time, code, path });
    const hash = `sha256=${crypto.enc.Base64.stringify(
        crypto.HmacSHA256(payload, token)
      )}`;
    return {
        time, 
        code,
        hash
    }
}