import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import { ReactComponent as WhatsAppIcon } from "images/whatsapp-icon-2.svg";

import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import DeliberyGirlImage from "images/delivery-image-girl.png";
import MisionImage from "images/mision-image.png";
import VisionImage from "images/vision.png";
import DownloadApp from "components/cta/DownloadApp.js";
import FAQ from "components/faqs/SingleCol.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Payments from "images/pagos.png";
import styled from "styled-components";

const HighlightedText = tw.span`text-primary-500`;
const Container = tw.div`font-display min-h-screen text-secondary-500 p-8 overflow-hidden`;
const WhatsAppButton = styled.a`
  ${tw`fixed bottom-0 right-0 mb-2 mr-2 w-16 h-16  text-white rounded-full z-50`}
  svg {
    ${tw`w-full h-full`}
  }
`;
const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4  -skew-x-12 inline-block`;
const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;

export default () => {
  return (
    <>
      <WhatsAppButton href="https://wa.link/8xa2nt" target="_blank">
        <WhatsAppIcon />
      </WhatsAppButton>
      <AnimationRevealPage>
        <Hero />
        <Features
          id={"services"}
          heading={
            <>
              Nuestros<HighlightedText> Servicios</HighlightedText>
            </>
          }
        />
        <FeatureStats />
        <Container id="about_us">
          <MainFeature
            subheading=""
            heading={
              <>
                Nuestra<HighlightedText> Misión</HighlightedText>
              </>
            }
            description="
        Damos a nuestros clientes la mejor experiencia en entregas,
        queremos impactar vidas, cambiar personas y aportar
        al mejoramiento de las empresas"
            imageSrc={MisionImage}
            textOnLeft={false}
          />
          <MainFeature
            subheading=""
            heading={
              <>
                Nuestra<HighlightedText> Visión</HighlightedText>
              </>
            }
            description="
        Crecer de la mano con todo tipo de emprendimientos,
        negocios, y empresas que deseen lograr un impacto
        positivo con cada uno de sus clientes. Seremos una
        compañía que se caracterice por valorar a sus
        colaboradores y darles la oportunidad de tener 
        una mejor calidad de vida. "
            imageSrc={VisionImage}
            textOnLeft={true}
          />
          <FeatureWithSteps
            // subheading={<Subheading>STEPS</Subheading>}
            subheading=""
            heading={
              <>
                Razones por las que debes
                <HighlightedText> agendar nuestros servicios.</HighlightedText>
              </>
            }
            textOnLeft={false}
            imageSrc={DeliberyGirlImage}
            imageDecoratorBlob={true}
            decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
            steps={[
              {
                heading: "Seriedad y cumplimiento",
                description:
                  "Nos encargamos de recoger y entregar tu paquete en el lugar acordado.",
              },
              {
                heading: "Seguridad y eficacia",
                description:
                  "Realizamos todos tus trámites con total responsabilidad y seguridad.",
              },
              {
                heading: "Personal capacitado",
                description:
                  "Contamos con personal dispuesto a atender todas tus solicitudes de manera profesional y personalizada.",
              },
            ]}
          />
        </Container>

        {/* <Testimonial 
        heading={<>Our Clients <HighlightedText>Love Us</HighlightedText></>}
      />
      <Pricing 
        heading={<>Flexible <HighlightedText>Plans</HighlightedText></>}
      />
      <FAQ
        heading={<>Any <HighlightedText>Questions ?</HighlightedText></>}
      />
      <Blog
        subheading="Blog"
        heading={<>We love <HighlightedText>Writing</HighlightedText></>}
      />
      <GetStarted/> */}
        <MainFeature
          id="payments"
          subheading=""
          heading={
            <>
              Pagos <HighlightedText> Recargas</HighlightedText>
            </>
          }
          description="
          Por cada uno de nuestros canales de atención puedes realizar pagos de
          Facturas como EPM, CLARO, TIGO, Recargas de paquetes a todos los
          operadores, Apuestas deportivas WPLAY, BETPLAY, RUSHBET y muchas
          más. "
          imageSrc={Payments}
          textOnLeft={true}
        />
        {/* <DownloadApp
          text={
            <>
              La gente que te rodea accede a Un Mundo de Servicios usando{" "}
              <HighlightedTextInverse>
                Motocity Express App.
              </HighlightedTextInverse>
            </>
          }
        /> */}
        <FAQ
          subheading={<Subheading>Preguntas frecuentes</Subheading>}
          description=""
          heading={
            <>
              ¿Tienes alguna<HighlightedText> pregunta ?</HighlightedText>
            </>
          }
          faqs={[
            {
              question: "¿Cuáles son las tarifas?",
              answer:
                "Para poderte cotizar el servicio, necesitamos dirección de recogida y de entrega, también el tamaño y peso del paquete a transportar.",
            },
            {
              question: "Cuál es el horario de atención?",
              answer: "Atendemos de lunes a sábado de 7 a.m. a 7 p.m. ",
            },
            {
              question: "¿En qué lugar están ubicados?",
              answer:
                "Nos encuentras en Medellín, en el barrio Prado Centro, Cra 43. #58-26.",
            },
            {
              question: "¿Qué medios de pago reciben?",
              answer:
                "Puedes pagar en efectivo, transferencia bancaria, QR, Nequi o Daviplata",
            },
            {
              question:
                "¿Qué debo de hacer para ser un mensajero de Motocity Express?",
              answer:
                "Debes enviarnos tu hoja de vida al correo: motocity-express@hotmail.com o a nuestro WhatsApp 312-874-05-79",
            },
            {
              question:
                "Soy una empresa interesada en su servicio de domicilios, ¿Qué debo de hacer para que ustedes empiecen a repartir mis pedidos?",
              answer:
                "Puedes dejarnos tu número de contacto para que se comuniquen contigo o si lo prefieres escribirnos a nuestras líneas de atención para darte una asesoría pronta y personalizada. ",
            },
            {
              question: "¿En qué ciudades ofrecen los servicios?",
              answer:
                "Trabajamos para toda el Área metropolitana del Valle de Aburrá ",
            },
          ]}
        />
        <Footer />
      </AnimationRevealPage>
    </>
  );
};
