import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

import BoxIconImage from "images/box.svg";
import PaidIconImage from "images/paid.svg";
import TimerIconImage from "images/timer.svg";
import HomePinIconImage from "images/homa_pin.svg";
import MotorcycleIconImage from "images/motorcycle.svg";
import ClinicalNotes from "images/clinical_notes.svg";
import SimpleIconImage from "images/simple-icon.svg";
import InventoryIconImage from "images/inventory.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-5 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-3 flex-shrink-0`}
    img {
      ${tw`w-10 h-10`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({
  id= "",
  cards = null,
  heading = "Servicios garantizados",
  subheading = "Todo lo encuentras en Motocity Express",
  description = `Primero debes definir el tipo de servicio que necesitas, 
  después de elegir el servicio nos puedes contactar por 
  cualquiera de nuestros canales de atención (WhatsApp, PBX, Correo electrónico o Instagram)
  para brindarte el servicio seleccionado
  `,
}) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    {
      imageSrc: HomePinIconImage,
      title: "Domicilios",
      description: "Servicio de recogida y entrega de un paquete, desde y hacia un destino final"
    },
    {
      imageSrc: BoxIconImage,
      title: "Encomiendas",
      description:
        "Recoger, comprar o pagar algo que necesites. Pueden ser varios destinos",
    },
    {
      imageSrc: MotorcycleIconImage,
      title: "Productos para motos",
      description:
        "Todo tipo de repuestos, accesorios y calcomanías para tu moto",
    },
    {
      imageSrc: PaidIconImage,
      title: "Pagos y recaudos",
      description:
        "Pagos de Facturas, Recargas de paquetes a todos los operadores, Apuestas deportivas y muchas más.",
    },
    {
      imageSrc: ClinicalNotes,
      title: "Tramites y diligencias",
      description:
        "Diligencias bancarias, Notarias, Secretaria de Tránsito, Reclamar Medicamentos, etc.",
    },
    {
      imageSrc: InventoryIconImage,
      title: "Transporte de mercancía",
      description:
        "Aplica para tiendas, empresas y todo tipo de emprendimientos. Entregamos productos a tu cliente final.",
    },
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container id={id}>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description ||
                    "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud. Me he puesto en 3 partes"}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob />
    </Container>
  );
};
