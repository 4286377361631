import React from "react";
import tw from "twin.macro";
import HeaderBase, {
  NavLinks,
  NavLink,
  PrimaryLink,
} from "components/headers/light.js";
import { SectionHeading } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import {
  Container,
  ContentWithVerticalPadding,
} from "components/misc/Layouts.js";
import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";
import Slider from "components/slider/Slider";

const Header = tw(HeaderBase)`max-w-none`;
const Row = tw.div`flex flex-col lg:flex-row justify-evenly items-center lg:pt-8 max-w-screen-2xl mx-auto sm:px-8`;
const Column = tw.div``;
const TextColumn = tw(
  Column
)`mr-auto lg:mr-0 max-w-lg lg:max-w-xl xl:max-w-2xl`;
const Heading = tw(
  SectionHeading
)`text-left text-primary-900 leading-snug xl:text-6xl`;
const Description = tw(
  SectionDescription
)`mt-4 lg:text-base text-gray-700 max-w-lg`;
const FeatureList = tw.ul`mt-4 leading-loose`;
const Feature = tw.li`flex items-center`;
const FeatureIcon = tw(CheckboxIcon)`w-5 h-5 text-primary-500`;
const FeatureText = tw.p`ml-2 font-medium text-gray-700`;

export default ({
  heading = "Un Mundo de Servicios",
  description = "Bienvenido a Motocity Express, tu puerta de entrada a un mundo de servicios excepcionales. Somos mucho más que una empresa de entregas; somos un equipo apasionado que nació de un mundo de ideas innovadoras y una familia con la misión de inspirar, marcar la diferencia e impactar vidas.",
  imageSrc = "https://img.freepik.com/fotos-premium/mago-longitud-completa-repartidor-asiatico-sobre-fondo-azul_861875-2133.jpg?size=626&ext=jpg",
  imageDecoratorBlob = true,
  primaryButtonUrl = "https://google.com",
  primaryButtonText = "Comencemos",
  buttonRounded = true,
  features = ["Rápido", "Seguro", "Confiable"],
  // testimonial = {
  //   quote:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  //   customerName: "Charlotte Hale",
  //   customerCompany: "Delos Inc.",
  // },
}) => {
  const buttonRoundedCss = buttonRounded && tw`rounded-full`;

  const images = [
    {
      src: "https://img.freepik.com/fotos-premium/mago-longitud-completa-repartidor-asiatico-sobre-fondo-azul_861875-2133.jpg?size=626&ext=jpg",
    },
    {
      src: "https://img.freepik.com/fotos-premium/personaje-dibujos-animados-mensajeria-entrega-parado-frente-cajas_88157-129.jpg",
    },
    {
      src: "https://st2.depositphotos.com/7874342/10840/v/450/depositphotos_108404352-stock-illustration-fast-delivery-the-guy-on.jpg",
    },
  ];

  return (
    <>
      <Header />
      <Container>
        <ContentWithVerticalPadding>
          <Row>
            <TextColumn>
              <Heading>{heading}</Heading>
              <Description>{description}</Description>
              {/* <PrimaryButton
                as="a"
                href={primaryButtonUrl}
                css={buttonRoundedCss}
              >
                {primaryButtonText}
              </PrimaryButton> */}
              <FeatureList>
                {features.map((feature, index) => (
                  <Feature key={index}>
                    <FeatureIcon />
                    <FeatureText>{feature}</FeatureText>
                  </Feature>
                ))}
              </FeatureList>
            </TextColumn>
            <Slider imagesSrc={images} />
          </Row>
        </ContentWithVerticalPadding>
      </Container>
    </>
  );
};
